<template>
  <div class="common_sub_container">
    <h2 class="sections_to_hide">DELIVERY REPORTS</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table class="scoped_cell_width_adj">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose a Report Type
                  <span></span>
                </label>
                <select v-model="post_data.booking_type" @change="resetData">
                <!--  <option value="all">All</option>-->
                  <option value="Batch Upload">Batch Upload</option>
                  <option value="Courier">Courier</option>
                  <option value="Messenger">Messenger</option>
                  <option value="Dispatched Hub Transmittal Slip">Dispatched Hub Transmittal Slip</option>
                  <option value="encode">Encode</option>
                  <option value="Hub Transmittal Slip">Hub Transmittal Slip</option>
                  <option value="mailroom">Mailroom</option>
                  <option value="Transmittal Slip">Transmittal Slip</option>
                   <option value="Transport">Transport</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>

                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged"
                ></flat-pickr>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Request Hub/Mailroom Location
                  <span></span>
                    </label>
                  <div v-if="post_data.booking_type!='Transport'">
              
                <select  v-model="post_data.division">
                  <option value="all">All</option>
                  <option
                    v-for="(division,i) in divisions"
                    :key="'divisions'+i"
                    :value="division.name"
                  >{{division.name}}</option>
                </select>
                </div>
                
                <div v-else>
                     <select  v-model="post_data.division">
                  <option value="all" disabled>All</option>
              
                </select>
                </div>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Request Branch
                  <span></span>
                </label>
                <select v-model="post_data.branch">
                  <option value="all">All</option>
                  <option
                    v-for="(unit,i) in units"
                    :key="'units'+i"
                    :value="unit.name"
                  >{{unit.name}}</option>
                </select>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Group By
                  <span></span>
                </label>
                <select v-model="post_data.groupby">
                  <option value="branch">Branch</option>
                  <option value="provider_id">Carrier</option>
                  <option value="cost_center_name">Cost Center</option>
                  <option value="division" v-if="post_data.booking_type!='Transport'">Hub/Mailroom Location</option>
                  <option value="booking_type">Report Type</option>
                  <option value="delivery_service">Service</option>
                  <option value="goods" v-if="post_data.booking_type!='Transport'">Type of Goods</option>
                   <option value="goods" v-if="post_data.booking_type=='Transport'">Transport Type</option>
                  
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
              <div v-if="post_data.booking_type!='Transport'">
                <label>
                  Type of Goods
                  <span></span>
                </label>
                <select v-model="post_data.type_of_good">
                  <option value="all">All</option>
                  <option
                    v-for="(good,i) in goods"
                    :key="'goods'+i"
                    :value="good.type"
                  >{{good.type}}</option>
                </select>
              </div>
                   
              <div v-else>
               <label>
                  Transport Type
                  <span></span>
                </label>
                <select v-model="post_data.type_of_good">
                  <option value="corporate">Corporate</option>
                </select>
              </div></div>
            </td>

            <td>
              <div v-if="post_data.booking_type != 'encode'">
                <div class="label_and_element_wrapper">
                  <label>
                    Carrier
                    <span></span>
                  </label>
                  <div v-if="post_data.booking_type!='Transport'">
                  <select v-model="post_data.delivery_provider" @change="fetchService">
                    <option value="all">All</option>
                    <option
                      v-for="(carrier,i) in carriers"
                      :key="'carriers'+i"
                      :value="carrier.id"
                    >{{carrier.name}}</option>
                  </select>
                  </div>
                  <div v-else>
                    <select v-model="post_data.delivery_provider" @change="fetchService">
                    <option value="Transport">Transport</option>
                   
                  </select>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div v-if="post_data.booking_type != 'encode'">
                <div class="label_and_element_wrapper">
                  <label>
                    Service
                    <span></span>
                  </label>
                  
                  <select v-model="post_data.delivery_service">
                    <option value="all">All</option>
                    <option
                      v-for="(service,i) in services"
                      :key="'services'+i"
                      :value="service.name"
                    >{{service.name}}</option>
                  </select>
             
                 
                </div>
              </div>
            </td>
  <td>
              <div v-if="post_data.booking_type != 'encode'">
                <div class="label_and_element_wrapper">
                  <label>
                    Cost Center
                    <span></span>
                  </label>
                  <select v-model="post_data.cost_center" @change="fetchCostCenter">
                    <option value="all">All</option>
                    <option
                      v-for="(cost_center,i) in costcenters"
                      :key="'costcenters'+i"
                      :value="cost_center.name"
                    >{{cost_center.name}}</option>
                  </select>
                </div>
              </div>
            </td>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  Status
                  <span></span>
                
                </label >
            <div v-if="post_data.booking_type != 'Transport'">
                <select   v-model="post_data.status">
                  <option value="all">All</option>
                  <option value="Created">Created</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Dispatched">Dispatched</option>
                  <option value="Not Home">Not Home</option>
                  <option value="PickedUp">PickedUp</option>
                  <option value="Received">Received</option>
                  <option value="RTS">RTS</option>
                  <option value="Transmitted">Transmitted</option>
                  <option value="WC">WC</option>
                </select>
  </div>
                <div v-else>
                  <select   v-model="post_data.status">

                    <option value="all">All</option>
                    <option value="Booked">Booked</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Picked Up">Picked Up</option>
                    <option value="On-Ride">On-Ride</option>
                    <option value="Arrived">Arrived</option>
                    <option value="Dispatched">Dispatched</option>
                  </select>
  </div>
   
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                  GL Code
                  <span></span>
                </label>
                <input type="text" v-model="post_data.code_value">
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper" v-if="Object.keys(reports).length > 0">
        <header>
          <h3>Registry Dispatch System Monthly Summary</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div
          class="despatch_summary_table_container"
          v-for="(report,key,i) in reports"
          :key="'delivery_report'+i"
        >
          <h2>{{key}}</h2>
          <table>
        <tr>
           
              <td v-if="post_data.booking_type=='Transport'"><b>Job Date</b></td>
              <td v-if="post_data.booking_type=='Transport'"><b>Job Time</b></td>
              <td><span v-if="post_data.booking_type!='Transport'"><b>Con No</b></span>
              <span v-else><b> Receipt No</b></span></td>
                <td><b>Service</b></td>
              <td><b>Requestor/Passenger Name</b></td>
              <td><b>Request Branch</b></td>
              <!-- <td><b>Cost Center</b></td> -->
               <td v-if="post_data.booking_type=='Transport'"><b>Priority</b></td>
               <td v-if="post_data.booking_type=='Transport'"><b>Pickup Address</b></td>
               <td v-if="post_data.booking_type=='Transport'"><b>Drop Address</b></td>
               <td v-if="post_data.booking_type=='Transport'"><b>Pickup Date & Time</b></td>
               <td v-if="post_data.booking_type=='Transport'"><b>Pickup Instructions</b></td>
               <td v-if="post_data.booking_type=='Transport'"><b>Cost</b></td>
               <td v-if="post_data.booking_type=='Transport'"><b>Transport Company</b></td>
               <td v-if="post_data.booking_type=='Transport'"><b>Vehicle</b></td>
               <td v-if="post_data.booking_type=='Transport'"><b>Driver Name</b></td>
              <td v-if="post_data.booking_type!='Transport'"><b>Country</b></td>
               
              <td> <span v-if="post_data.booking_type!='Transport'"><b>Type of Goods</b></span>
              <span v-else><b>Transport Type</b></span></td>
              <td><b>Service</b></td>
              <td>  <span v-if="post_data.booking_type!='Transport'"><b>Pieces</b></span>
              <span v-else><b>Pax</b></span></td>
              <td><b>Requested Date</b></td>
              <td><b>Status</b></td>
              <td><b>Code</b></td>
              <td><b>Code Value</b></td>
              <td><span v-if="post_data.booking_type!='Transport'"><b>Delivered Date</b></span>
              <span v-else><b>Drop Date</b></span></td>
            </tr>
            <tr v-for="(courier,i) in report" :key="i">
             <td  v-if="post_data.booking_type=='Transport'">{{moment(courier.status_date,"MM-DD-YYYY").format("DD-MM-YYYY")}}</td>
              <td  v-if="post_data.booking_type=='Transport'">{{courier.status_time}}</td>
             <!-- <td>{{courier.booking_prefix}}{{courier.booking_id}}</td> -->
             <td>{{courier.airway_bill}}</td>
              <td>{{courier.delivery_service}}</td>
              <td>{{courier.requester_name}}</td>
              <td>{{courier.branch}}</td>
               <!-- <td>{{courier.cost_center_name}}</td>  -->
                <td v-if="post_data.booking_type=='Transport'">{{courier.delivery_type}}</td>
                     <td v-if="post_data.booking_type=='Transport'">{{courier.pickup_address_1}} {{courier.pickup_address_2}} {{courier.pickup_city}}</td>
               <td v-if="post_data.booking_type=='Transport'">{{courier.delivery_address_1}} {{courier.delivery_address_2}} {{courier.delivery_city}}</td>
               <td v-if="post_data.booking_type=='Transport'">{{moment(courier.pickup_date,"MM-DD-YYYY").format("DD-MM-YYYY")}} {{courier.pickup_time}}</td>
               <td v-if="post_data.booking_type=='Transport'">{{courier.pickup_instructions}}</td>
               <td v-if="post_data.booking_type=='Transport'">{{courier.cost}}</td>
               <td v-if="post_data.booking_type=='Transport'">{{courier.dispatch_company}}</td>
               <td v-if="post_data.booking_type=='Transport'">{{courier.vehicle_model}}</td>
               <td v-if="post_data.booking_type=='Transport'">{{courier.driver_name}}</td>
              <td v-if="post_data.booking_type!='Transport'">{{courier.delivery_country}}</td>
              <td>{{courier.goods}}</td>
               <td>{{courier.delivery_service}}</td>
              <td>
              <span v-if="post_data.booking_type!='Transport'">{{courier.item_qty}}</span>
              <span v-else>{{courier.no_of_passengers}}</span>
              </td>
              <td>{{moment(courier.request_date,"YYYY-MM-DD").format("DD-MM-YYYY")}}</td>
              <td>{{courier.status}}</td>
              <td>{{courier.custom_code}}</td>
              <td>{{courier.custom_code_value}}</td>
              <td>{{courier.status_date}} {{courier.status_time}}</td>
            </tr>
          </table>
        </div>
      </div>
      <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "ReportsDelivery",
  components: {
    flatPickr
  },
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      date: null,
      enableTime: true,
      carriers: [],
      costcenters: [],
      goods: [],
      services: [],
      divisions: [],
      units: [],
      reports: {},
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        booking_type: "Transport",
        type_of_good: "corporate",
        delivery_provider: "Transport",
        cost_center: "all",
        delivery_service: "all",
        groupby: "booking_type",
        division: "all",
        branch: "all",
        status: "all",
        custom_code:""
      },
      api_url: "",
      configDate: {
        mode: "range",
        format: "d-m-Y",
          altFormat: "d-m-Y",
          altInput: true
      },
      show_data: false
    };
  },
  created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Reports", "Delivery Report"]);
    this.fetchCarrier();
    this.fetchCostCenter();
    this.fetchGood();
    this.fetchDivision();
    this.fetchUnit();
    this.fetchService();
  },
  methods: {
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    fetchDivision() {
      this.axios
        .get("/api/division/")
        .then(response => {
          this.divisions = response.data.divisions;
        })
        .catch(error => {
          this.toast.error();
        });
    },
      fetchCostCenter() {
      this.axios
        .get("/api/cost/")
        .then(response => {
          this.costcenters = response.data.cost_centers;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchUnit() {
      this.axios
        .get("/api/branch/view")
        .then(response => {
          this.units = response.data.branches;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchGood() {
      this.axios
        .get("/api/goods/")
        .then(response => {
          this.goods = response.data.goods;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchCarrier() {
      this.axios
        .get("/api/provider/")
        .then(response => {
          this.carriers = response.data.providers;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchService() {
      this.services = [];
      if (this.post_data.delivery_provider != "") {
        this.axios
          .get("/api/provider/services/" + this.post_data.delivery_provider)
          .then(response => {
            this.services = response.data.services;
             if(this.services.length > 0){
              this.post_data.delivery_service = this.services[0].name;
            }
          })
          .catch(error => {
            this.toast.error();
          });
      }
    },
    resetData() {
      this.show_data = false;
       if (this.post_data.booking_type != "Transport") {
         this.post_data.delivery_provider='all'
         this.post_data.type_of_good='all'
       }
       else{
         this.post_data.delivery_provider='Transport'
          this.post_data.type_of_good='corporate'
       }
      this.reports = {};
    },
    fetchReport() {
      console.log(this.post_data);
       console.log('this.post_data');
      this.axios
        .post("/api/reports/getdeliveryreports", this.post_data)
        .then(response => {
          console.log(response);
          this.show_data = true;
          this.reports = response.data.data;
        })
        .catch(error => {
          console.log(e);
        });
    },
    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/getdeliveryreports/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: 20%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>